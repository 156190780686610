<template>
	      
                    <b-col v-if="section.items.length >0">
                        <MenuSectionTitle :title="section.title" :subtitle="section.subtitle" :desc="section.desc" />
                        <MenuSectionItems ref="MenuSectionItemsRef" :sectionItems="sectionItems" />
                         <b-row class="my-3">
                           <b-col cols="12" md="8" class="mc-section-footer mx-auto"><span v-html="section.disclamer"></span></b-col>
                         </b-row>
                     </b-col>
                

</template>	


<script>
// @ is an alias to /src
import MenuSectionItems from '@/modules/menu/components/MenuSectionItems.vue';
 
import MenuSectionTitle from '@/modules/menu/components/MenuSectionTitle.vue';
 
export default {
  name: 'MenuSection',
  components:{
  	MenuSectionItems,
 
  	MenuSectionTitle
  },
  props:{
  	sectionTitle:String,
    sectionSubtitle:String,
  	sectionDesc:String,
  	sectionDisclamer:String,
  	sectionItems:[Array,Object],
    section:[Array,Object]

  },
 
}
</script>
