// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    websiteData: [],
    loading: false,
  },
  mutations: {
    setWebsiteData(state, data) {
      state.websiteData = data;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
  },
  actions: {
    // Acción para obtener datos del sitio web desde una API con precarga y un setTimeout simulado
    fetchWebsiteDataWithPreload({ commit, state }) {
      // Verificar si los datos ya han sido cargados
      if (state.websiteData.length === 0) {
        // Si no se han cargado, establecer loading en true
        commit('setLoading', true);

        // Emular una carga asincrónica con un setTimeout
        return new Promise((resolve) => {
          setTimeout(() => {
            fetch("https://staging.menucheck.us/restaurants/api/9054")
              .then((response) => response.json())
              .then((websiteJson) => {
                commit('setWebsiteData', websiteJson);
                resolve(); // Resolver la promesa cuando los datos se cargan
              })
              .catch((error) => {
                console.error('Error fetching website data:', error);
                resolve(); // Resolver la promesa incluso si hay un error
              })
              .finally(() => {
                commit('setLoading', false);
              });
          }, 10); // Simular una carga de 2 segundos
        });
      } else {
        // Si los datos ya están cargados, no es necesario hacer otra llamada
        return Promise.resolve();
      }
    },
  },
});