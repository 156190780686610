<template>
	 <!--section-title-->
    <b-row class="row-section   my-3">
      <b-col cols="12" md="10" class="text-center mx-auto">
		    <h2 class="text-center  mc-section-title my-2" v-html="title"> </h2>
        <div class="mc-section-subtitle my-1"><h5  v-html="subtitle"></h5></div>
				<div class="mc-section-desc my-1" v-html="desc"></div>
			</b-col>
		</b-row>
    <!--/section-title-->
</template>


<script>
// @ is an alias to /src
 
 
export default {
  name: 'MenuSectionTitle',
  props:{
  	title:String,
    subtitle:String,
  	desc:String
  },
 
}
</script>
