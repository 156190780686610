  <template>
  <b-row class="">
       <b-col class="col-12 col-sm-6 col-md-3 my-3" v-for="(item, indexSection) in sectionItems" :key="indexSection">
          <MenuSectionItem ref="MenuSectionItemRef" :item="item" />
        </b-col>
  </b-row>
</template> 


 <script>
import MenuSectionItem from '@/modules/menu/components/MenuSectionItem.vue';
  export default {
    name: 'MenuSectionItems',
    props:{
      sectionItems: Array
    },
    components: { 
      MenuSectionItem
     },
     data() {
      return {
  
      }
    },
    methods:{

    },
   mounted(){
       
       
  }
}
 </script>

<style lang="less">
 
</style>

