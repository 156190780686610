  <template>
          
            <b-card class="my-3 text-center mc-section-item ">
                    <img :src="item.image.big" alt="" lazy v-if="item.image.big!=null" class="img-fluid mc-item-img rounded ">
                    <h4 class="mc-section-item-title my-3" v-html="item.title"> </h4>
                    <h5 class="mc-section-item-price">$ <span v-html="item.price"></span></h5>
                    <b-card-text class="mc-section-item-desc" >
                    <div v-html="item.desc"></div>
               </b-card-text>
              
            </b-card>
        
</template>	


 <script>
 	export default {
    name: 'MenuSectionItem',
    props:{
        item:Object,
        index:Number,
    	  image: Object,
        title:String,
        price:String,
        desc:String
    },
    components: { 
    
     },
     data() {
      return {
  
      }
    },
    methods:{
       
    },
   mounted(){
     	 
     	 
  }
}
 </script>

<style lang="less">
 
</style>

