<template>
  <div id="app">
   
    <MenuDefault :Menues="bussinesMenus" :showTitle="true" :strapTitle="'Our Menu'"  />
   
  </div>
</template>

<script>
import menuMixin from '@/mixins/menuMixin';
 import MenuDefault from '@/modules/menu/MenuDefault.vue';
 

export default {
  name: 'App',
  mixins: [menuMixin],
  components: {
     MenuDefault,
  },
  data() {
    return {
      website: [],
      bussinesLocations: [],
      loading: false,
      locationsLoader: false,
      bussinesMenus:[],
      showSpinner:true
    };
  },
  computed: {
    websiteData() {
      return this.$store.state.websiteData;
    },
  },
  created() {
    // Realizar la carga de datos en el ciclo de vida created
    this.loadWebsiteData();
  },
  mounted() {
    // Realizar la carga de datos en el ciclo de vida mounted
    this.loadWebsiteData();
  },
  watch: {
    // Puedes agregar watchers si es necesario
  },

  methods: {
     getSectionById(menuID, sectionID) {
      this.$root.$emit('getSectionById', menuID, sectionID);
    },
    loadWebsiteData() {
      this.loading = true; // Establecer loading en true antes de la llamada
      this.$store
        .dispatch('fetchWebsiteDataWithPreload')
        .then(() => {
          // Puedes realizar acciones adicionales después de cargar los datos (si es necesario)
          this.locationsLoader = true;
        })
        .catch((error) => {
          console.error('Error al cargar datos:', error);
        })
        .finally(() => {
          this.loading = false; // Establecer loading en false después de la llamada
          this.locationsLoader = true;
          this.bussinesLocations = this.websiteData.bussines_locations;
          this.bussinesMenus = this.websiteData.bussines_menus;
          this.website = this.websiteData;
          console.log(this.bussinesMenus)
        });
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
