export default {
 
  methods: {
       getMenuAndSectionById(menuArray, menuId, sectionId) {
              // Buscar el menú por ID
              const selectedMenu = menuArray.find(menu => menu.id === menuId);

              if (selectedMenu) {
                // Buscar la sección dentro del menú por ID
                const selectedSection = selectedMenu.sections.find(section => section.id === sectionId);

                if (selectedSection) {
                  return {
                    menu: selectedMenu,
                    section: selectedSection
                  };
                } else {
                  console.error(`No se encontró la sección con ID ${sectionId} en el menú con ID ${menuId}.`);
                }
              } else {
                console.error(`No se encontró ningún menú con ID ${menuId}.`);
              }

              return null;
            },

        getMenuById(menuArray, menuId) {
              // Buscar el menú por ID
              const selectedMenu = menuArray.find(menu => menu.id === menuId);

              if (selectedMenu) {
                return selectedMenu;
              } else {
                console.error(`No se encontró ningún menú con ID ${menuId}.`);
                return null;
              }
            },
        
        getSectionById(menuArray, menuId, sectionId) {
          // Buscar el menú por ID
          const selectedMenu = menuArray.find(menu => menu.id === menuId);

          if (selectedMenu) {
            // Buscar la sección por ID dentro del menú seleccionado
            const selectedSection = selectedMenu.sections.find(section => section.id === sectionId);

            if (selectedSection) {
              return selectedSection;
            } else {
              console.error(`No se encontró ninguna sección con ID ${sectionId} en el menú con ID ${menuId}.`);
              return null;
            }
          } else {
            console.error(`No se encontró ningún menú con ID ${menuId}.`);
            return null;
          }
        },
        getSectionAndMenuById(menuArray, sectionId) {
          // Buscar el menú y la sección por ID
          for (const menu of menuArray) {
            const selectedSection = menu.sections.find(section => section.id === sectionId);

            if (selectedSection) {
              // Devolver tanto el menú como la sección
              return { menu, section: selectedSection };
            }
          }

          console.error(`No se encontró ninguna sección con ID ${sectionId}.`);
          return null;
        }

  },
};