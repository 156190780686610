  <template>
      <div>
        <b-card tag="article" style="max-width: 100%" 
                        :img-src="menu.menu_images.big"
                        img-alt="Image"
                        img-top
                        class="mb-4 text-center mc-menu-card ">
                            <h2 class="mc-menu-title my-3"><b-link @click="getMenuParent();"><span  v-html="menu.title"></span></b-link></h2>
                            <b-button class="text-uppercase btn " href="#" @click="getMenuParent();">View Menu</b-button>
        </b-card>   
      </div>
</template>	


 <script>
 	export default {
    name: 'MenuItem',
    props:{
        menu:Object,
        index:Number,
    	  image: Object,
        title:String,
        price:String,
        desc:String
    },
    components: { 
    
     },
     data() {
      return {
  
      }
    },
    methods:{
       getMenuParent(){
          const index =this.index;
          const menu = this.menu;
          this.$parent.getMenu(index,menu);
       }
    },
   mounted(){
     	 
     	 
  }
}
 </script>

<style lang="less">
 
</style>

