<template>
    <div  id="module-mc-menu"  ref="header"> 
      
        <div v-if="showMenus===true" class="row" id="mc-menu-list">
             <section class="strap strap-menu py-5">
                    <b-container  fluid="sm">
                          <b-row class=" my-3" v-if="showTitle==true">
                            <b-col class="text-center"> 
                                <h2 class="menu-title">Our Menu</h2> 
                            </b-col>
                         </b-row>
                         

                      <b-row class="menu-row my-3 " v-if="showSpinner == false" >
                        <b-col
                            class="col-12 col-sm-6 col-md-4 mc-menu-item "
                            v-for="(menu, index) in filteredMenus"
                            :key="index"
                        >
                            <MenuItem ref="MenuItemRef" :menu="menu" />
                        </b-col>
                    </b-row>
                </b-container>
            </section>
       
        </div>
           
        <transition name="fade">
                
            <div v-if="showNavSections===true" id="mc-header" :class="{ 'sticky': isSticky }">
              <section class="strap strap-mc-sections-header" ref="mcSectionsNav">

                      <b-container  fluid="sm">
                        <b-row class="my-3">
                            <b-col class="col-3 col-sm-3 col-md-3">
                                <b-button @click="backMenu" class="btn  ">
                                    <small>Back</small>
                                </b-button>
                            </b-col>
                            <b-col class="col-6 col-sm-6 col-md-6">
                                <h1 class="mc-menu-section-title" v-html="MenuTitle"></h1>
                              
                            </b-col>
                            <b-col class="col-3 col-sm-3 col-md-3 d-flex flex-row-reverse bd-highlight">
                               <b-dropdown id="dropdown-menu" text="Menu" class="m-md-2 align-self-end btn btn-sm">
                                    <b-dropdown-item 
                                        v-for="(menu, index1) in filteredMenus" 
                                        :key="index1"
                                        @click="getMenuClick(index1, menu);"
                                    >
                                        <span v-html="menu.title"></span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </b-row>
                       
                        <!--Menu-->

                        <!--Navbar menu-->
                        <b-row class="row-nav my-3" id="mc-navbar">
                            <b-col>
                              <b-navbar-nav class="menu-navbar">
                                <b-nav-item
                                    v-for="(section, index1) in filteredMenuSections"
                                    :key="index1"
                                    @click="getSection(section)"
                                    class="text-center mx-1 btn btn-sm menu-navbar-item mc-navbar-item"
                                >
                                    <span v-html="section.title"></span>
                                </b-nav-item>
                            </b-navbar-nav>
                            </b-col>
                        </b-row>
                        <!--/Navbar menu-->
                    </b-container>
              </section>
            </div>


         </transition>


           <div class="loader-spinner" v-if="showSpinner">
                    <div class="d-flex justify-content-center mb-3">
                        <b-spinner style="" id="spinner"></b-spinner>
                    </div>
                 </div>
         <transition name="fade">
            <section class="strap strap-menu-section-items" id="mc-sections"  :class="{ 'sticky-section': isSticky }">
               <div class="inner ">
                  <b-container  fluid="sm" v-if="showSpinner==false" >
 

                  <!--All sections-->
              
                   <b-row class="row-sections  mc-section  my-3" 
                        v-for="(section, index2) in MenuSections" 
                        :key="index2" 

                        v-show="showAllSections===true">
                            <MenuSection
                                :section="section"
                                :sectionItems="section.items"
                                :sectionTitle="section.title"
                                :sectionSubtitle="section.subtitle"
                                :sectionDesc="section.desc"
                                :sectionDisclamer="section.section_disclamer"
                             />
                         </b-row>

           <b-row class="row-sections  mc-section" fluid   v-if="showSpinner==false && showSelectedSection===true">
                    <MenuSection
                        :section="sectionSelected"
                        :sectionItems="sectionItems"
                        :sectionTitle="sectionTitle"
                        :sectionSubtitle="sectionSubtitle"
                        :sectionDesc="sectionDesc"
                        :sectionDisclamer="sectionDisclamer"

                     />
                </b-row>

           

                 </b-container>


               </div>
            </section>
 
        </transition>
    
    </div>
</template>
<script>
// @ is an alias to /src

import menuMixin from '@/mixins/menuMixin';
import MenuSection from '@/modules/menu/components/MenuSection.vue';
 import MenuItem from '@/modules/menu/components/MenuItem.vue';
 
 
export default {
    name: 'MenuDefault',
    mixins:[menuMixin],
    props: {
        showTitle:Boolean,
        strapTitle:String,
        Menues:[Object,Array],
        LocationName: {
            type: [String],
            required: false
          }
    },
    components: {
        
        MenuItem,
        MenuSection

    },
    data() {
        return {
            MenuTitle: '',
            MenuDesc: '',
            Menus: [],
            menu:[],
            MenuSections: [],
            Sections: [],
            Items: [],
            showMenus: true,
            showSections: false,
            showSectionsItems: false,
            showAllSections: false,
            showSelectedSection: false,
            showNavSections: false,

            sectionTitle: '',
            sectionDesc: '',
            sectionDisclamer:'',
            sectionSubtitle:'',
            sectionItems: [],
            showSpinner: false,
            isSticky: false,
            stickyOffset: 0,
            lastScrollTop: 0,
            sectionHeader:0,
            sectionSelected:{}
        }
    },
    computed: {
            filteredMenus() {
                return this.Menues.filter(menu => menu.sections.length > 0);
            },
            filteredMenuSections() {
        return this.MenuSections.filter(section => section.items.length > 0);
    }
        },
    methods: {
        backMenu() {
            
            this.showSections = false;
            this.showMenus = true;
            this.showAllSections = false;
            this.showSelectedSection = false;
            this.showNavSections = false;
            this.showPreload('mc-menu-list');

        },


        getSection(section) {
            this.sectionTitle = section.title;
            this.sectionDesc = section.section_desc;
            this.sectionItems = section.items;
            this.sectionSubtitle = section.section_subtitle;
            this.sectionDisclamer = section.section_disclamer;
            this.sectionSelected =  section;
            
            this.showSections = false;
            this.showSectionsItems = true;
            this.showAllSections = false;
            this.showSelectedSection = true;
            this.showPreload('mc-header');

        },


          getSectionTest(section) {
            this.sectionTitle = section.title;
            this.sectionDesc = section.section_desc;
            this.sectionItems = section.items;
            this.sectionSubtitle = section.section_subtitle;
            this.sectionDisclamer = section.section_disclamer;
            
            this.showSections = false;
            this.showSectionsItems = true;
            this.showAllSections = false;
            this.showSelectedSection = true;
            this.showMenus = false;
            this.showNavSections = true;

            this.showPreload('mc-header');

        },

       getMenu(index, menu) {
            this.showNavSections = true;
            this.showAllSections = true;
            this.MenuSections = menu.sections;
            this.MenuTitle = menu.title;
            this.MenuDesc = menu.menu_desc;
            //  this.showPreload();
            this.showMenus = false;
            this.showPreload('mc-header');


        },

        getMenuClick(index, menu) {
            this.showNavSections = true;
            this.showAllSections = true;
            this.MenuSections = menu.sections;
            this.MenuTitle = menu.title;
            this.MenuDesc = menu.menu_desc;
            //  this.showPreload();
            this.showMenus = false;

            this.showPreload('mc-header');

        },
        showPreload(section = '', time = 500) {
            this.showSpinner = true;
            setTimeout(() => {
                this.showSpinner = false;
                //this.scrollToElement(section, time);
            }, 300);
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        toggleModal() {
            // We pass the ID of the button that we want to return focus to
            // when the modal has hidden
            this.$refs['my-modal'].toggle('#toggle-btn')
        },
        handleScroll() {
            const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

            if (currentScrollTop > this.lastScrollTop) {
                // Scrolling down
                this.isSticky = currentScrollTop > this.stickyOffset ;
            } else {
                // Scrolling up
                this.isSticky = false;
            }

            this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;

        },
        scrollToElement(elementId = 'module-mc-menu', duration = 1000) {
            //window.removeEventListener("scroll", this.handleScroll);
            const element = document.getElementById(elementId);

            if (element) {
                const height =  element.height;
                const startPosition = window.scrollY ;
                const targetPosition = parseInt(element.offsetTop)  ;
                const distance = targetPosition - startPosition;
                const startTime = performance.now();

                function scrollStep(timestamp) {
                    const timeElapsed = timestamp - startTime;
                    const progress = Math.min(timeElapsed / duration, 1);

                    window.scrollTo(0, startPosition + distance * progress);


                    if (timeElapsed < duration) {
                        requestAnimationFrame(scrollStep);

                    }
                }
             //    window.addEventListener("scroll", this.handleScroll);
                requestAnimationFrame(scrollStep);
                
            }
        },


        getSectionJson(msg){
           console.log(msg)
        }
        
    },
    destroyed() {
        // Remove the scroll event listener when the component is destroyed
        window.removeEventListener("scroll", this.handleScroll);
    },
    created() {
            

    },
    mounted() {
         
         


            this.$root.$on('getMenuByID', (ID)=>{
              //console.log(ID);
                const menu =    this.getMenuById(this.$website.bussines_menus,ID);
               this.getMenu(1,menu);
            });


             this.$root.$on('getSectionById', (menuID,sectionID)=>{
              //console.log(ID);
                const menu = this.getMenuById(this.$website.bussines_menus,menuID);
                this.getMenu(1,menu);
                const section =  this.getSectionById(this.$website.bussines_menus,menuID,sectionID);
               this.getSectionTest(section);
               console.log(section);
            });

     //   this.getConfig();
        // Get the header element
        this.header = this.$refs.header;
        this.stickyOffset = this.header.offsetTop;
      
      
     
        window.addEventListener("scroll", this.handleScroll);
    }

}
</script>
<style lang="less">

</style>